import { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import media from '../utils/media'
import success from '../assets/img/successIcon.gif'

const FormContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px 16px;

  ${media.md`
    flex-wrap: nowrap;
    padding: 32px 24px;
  `}
`

const HoverForm = styled.div`
  border-radius: 10px;
  box-shadow: var(--box-shadow-primary-medium-with-larger-darker-glow);
  display: block;
  overflow: hidden;
  transition: all 250ms cubic-bezier(0.4, 0, 0.25, 1);
  max-width: 550px;
  text-align: left;
  margin: auto;

  ${media.md`
    width: calc(50% - 20px);
  `}
`

const CardContent = styled.form`
  padding: 48px 26px;
  background: white;
  margin: unset;

  ${media.md`
    padding: 48px;
  `}
`

const FormFieldsWrapper = styled.div`
  max-height: 750px;
  opacity: 1;
  transition: all 0.5s;

  ${({ $formResponse }) =>
    $formResponse &&
    css`
      max-height: 0;
      opacity: 0;
    `}
`

const FormTextInput = styled.input`
  width: 100%;
  height: 48px;
  box-shadow: var(--box-shadow-primary-small);
  border: solid 1px #cccccc;
  padding-left: 16px;
  border-radius: var(--border-radius);
  margin-bottom: 16px;
`

const FormTextarea = styled.textarea`
  width: 100%;
  height: 120px;
  box-shadow: var(--box-shadow-primary-small);
  border: solid 1px #cccccc;
  padding: 16px;
  border-radius: var(--border-radius);
  margin-bottom: 16px;
  resize: none;
`

const CardCta = styled.div`
  * {
    margin-bottom: 16px;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  && {
    display: none;
    margin: auto;
  }

  ${media.md`
    width: calc(50% - 20px);

    && {
      display: block;
    }
  `}
`

const SuccessIcon = styled.img`
  width: 100px;
  margin: auto;
  display: block;
`

const SubmitButton = styled.button`
  width: 100%;
  height: 55px;
  font-size: 14px;
  padding-top: 8px;
  border-radius: var(--border-radius);
  border: unset;
  box-shadow: var(--box-shadow-primary-small-with-darker-glow);
  color: var(--color-typography-on-primary);
  background-color: var(--color-background-primary);
  cursor: pointer;
`

const PageSectionContactForm = ({
  formTitle,
  formSubtext,
  image,
  pageUri,
  pageTitle,
  hubspot,
  formCompleteSubtext,
  formCompleteTitle,
}) => {
  const [formValue, setFormValue] = useState({})
  const [formResponse, setFormResponse] = useState(null)
  const [successfulSubmit, setSuccessfulSubmit] = useState(false)
  const { portalId, guid, formFields, submitText } = hubspot

  useEffect(() => {
    const mappedFields = formFields.map((i) => {
      return { name: i.name }
    })
    const reducedFields = mappedFields.reduce((allFields, field) => {
      const fields = allFields.name ? { [allFields.name]: '', [field.name]: '' } : { ...allFields, [field.name]: '' }
      return fields
    })
    setFormValue(reducedFields)
  }, [])

  const handleFormChange = (fieldName, input) => {
    setFormValue({ ...formValue, [fieldName]: input })
  }

  const handleFormResponse = (response, isError) => {
    setFormResponse(response)
    if (isError) {
      setFormResponse(response)
      setTimeout(() => {
        setFormResponse('')
      }, 3000)
    }
  }

  const submitForm = async (event) => {
    event.preventDefault()

    const formFields = []
    for (const [key, value] of Object.entries(formValue)) {
      formFields.push({ name: key, value })
    }
    const formData = {
      fields: formFields,
      context: {
        pageUri,
        pageName: pageTitle ?? 'VisualCV',
      },
    }

    const jsonFormData = JSON.stringify(formData)
    const postRequestOptions = {
      method: 'POST',
      json: true,
      body: jsonFormData,
      headers: { accept: 'application/json', 'Content-Type': 'application/json' },
    }

    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${guid}`,
        postRequestOptions,
      )
      const formResponse = await response.json()
      if (formResponse.inlineMessage && !formResponse?.status) {
        handleFormResponse(formResponse.inlineMessage, false)
        setSuccessfulSubmit(true)
      } else {
        if (formResponse.errors[0].errorType === 'REQUIRED_FIELD') {
          handleFormResponse('Please fill out all required fields.', true)
        }
        if (
          formResponse.errors[0].errorType === 'NUMBER_OUT_OF_RANGE' &&
          formResponse.errors[0].message.includes('fields.phone')
        ) {
          handleFormResponse('Please use a valid phone number.', true)
        }
        if (formResponse.errors[0].errorType === 'INVALID_EMAIL') {
          handleFormResponse('Please use a valid email address.', true)
        }
      }
    } catch (err) {
      handleFormResponse('There was an error submitting this form.', true)
    }
  }

  return (
    <FormContentWrapper>
      <StyledGatsbyImage
        image={getImage(image.localFile)}
        alt={image.title}
      />
      <HoverForm>
        <CardContent onSubmit={(e) => submitForm(e)}>
          {successfulSubmit && <SuccessIcon src={success} />}
          <CardCta>
            <h2>{successfulSubmit ? formCompleteTitle : formResponse || formTitle}</h2>
            {successfulSubmit && formCompleteSubtext
              ? formCompleteSubtext
              : !formResponse && formSubtext && <p>{formSubtext}</p>}
          </CardCta>
          <FormFieldsWrapper $formResponse={formResponse}>
            {formFields.map((field, index) => {
              switch (field.type) {
                case 'email':
                  return (
                    <FormTextInput
                      key={`emailInput${guid + index}`}
                      type="email"
                      placeholder={field.label}
                      name={field.name}
                      required={field.required}
                      onChange={(e) => handleFormChange(field.name, e.target.value)}
                    />
                  )
                case 'phone':
                  return (
                    <FormTextInput
                      key={`telInput${guid + index}`}
                      type="tel"
                      placeholder={field.label}
                      name={field.name}
                      required={field.required}
                      onChange={(e) => handleFormChange(field.name, e.target.value)}
                    />
                  )
                case 'single_line_text':
                  return (
                    <FormTextInput
                      key={`textInput${guid + index}`}
                      type="text"
                      placeholder={field.label}
                      name={field.name}
                      required={field.required}
                      onChange={(e) => handleFormChange(field.name, e.target.value)}
                    />
                  )
                case 'multi_line_text':
                  return (
                    <FormTextarea
                      key={`textArea${guid + index}`}
                      placeholder={field.label}
                      name={field.name}
                      required={field.required}
                      onChange={(e) => handleFormChange(field.name, e.target.value)}
                    />
                  )
                default:
                  return null
              }
            })}
            <SubmitButton>{submitText}</SubmitButton>
          </FormFieldsWrapper>
        </CardContent>
      </HoverForm>
    </FormContentWrapper>
  )
}

export default PageSectionContactForm
